import { action, makeObservable, observable, runInAction } from 'mobx';
import { EntityDto } from 'src/services/dto/entityDto';
import type { PagedFilterAndSortedRequest } from 'src/services/dto/pagedFilterAndSortedRequest';
import type { PagedResultDto } from 'src/services/dto/pagedResultDto';
import type { CreateOrUpdateInterventionInput } from 'src/services/intervention/dto/createOrUpdateInterventionInput';
import { GetInterventionOutput } from 'src/services/intervention/dto/getInterventionOutput';
import type { PushExternalInterventionInput } from 'src/services/intervention/dto/pushExternalInterventionInput';
import ExternalInterventionService from 'src/services/intervention/externalInterventionService';
import { BudgetType } from '../models/Interventions/BudgetType';

class ExternalInterventionStore {
    @observable interventions: PagedResultDto<GetInterventionOutput>;
    @observable editIntervention: CreateOrUpdateInterventionInput;

    constructor() {
        makeObservable(this);
    }

    @action
    async get(entityDto: EntityDto<string>) {
        const result = await ExternalInterventionService.get(entityDto);
        runInAction(() => {
            this.editIntervention = result;
        });
    }

    @action
    async getAll(pagedFilterAndSortedRequest: PagedFilterAndSortedRequest) {
        const result = await ExternalInterventionService.getAll(pagedFilterAndSortedRequest);
        runInAction(() => {
            this.interventions = result;
        });
    }

    @action
    async update(updateInterventionInput: CreateOrUpdateInterventionInput) {
        const result = await ExternalInterventionService.update(updateInterventionInput);
        runInAction(() => {
            this.interventions.items = this.interventions.items.map((x: GetInterventionOutput) => {
                if (x.id === updateInterventionInput.id) x = result;
                return x;
            });
        });
    }

    @action
    async pushUpdate(externalInterventionInput: PushExternalInterventionInput) {
        await ExternalInterventionService.pushUpdate(externalInterventionInput);
    }

    @action
    createIntervention() {
        this.editIntervention = {
            supplierId: '',
            showUrlInApp: false,
            vatPercentage: 0,
            kickbackFeePercentage: 0,
            stapKickbackFeePercentage: 0,
            kickbackFeeVATPercentage: 21,
            minNumberOfParticipants: 0,
            maxNumberOfParticipants: 0,
            startDateDetermination: undefined,
            id: undefined,
            type: undefined,
            canBeSuggestion: false,
            externalSource: undefined,
            externalId: undefined,
            costLines: [],
            themes: [],
            themeIds: undefined,
            taxWageExemption: undefined,
            budgetTypes: BudgetType.Employee,
            externalOrderUrl: undefined,
            externalOrderEmailAddress: undefined,
            excludeFromSubscription: false,
            localizations: [
                {
                    id: undefined,
                    parentId: '',
                    locale: {
                        id: 'nl',
                        description: 'Nederlands',
                    },
                    localeId: 'nl',
                    title: '',
                    summary: '',
                    description: '',
                    url: '',
                    location: '',
                    durationAmount: 0,
                    durationUnit: '',
                },
            ],
        };
    }
}

export default ExternalInterventionStore;
