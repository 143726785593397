import { action, makeObservable, observable, runInAction } from 'mobx';
import BudgetService from 'src/services/budget/budgetService';
import type { AdjustableBudgetDto } from 'src/services/budget/dto/adjustableBudget';
import { EmployeeBudgetOutput } from 'src/services/budget/dto/employeeBudgetOutput';
import type { GetEmployeeBudgetInput } from 'src/services/budget/dto/getEmployeeBudgetInput';
import type { GetEmployeeSpendingInput } from 'src/services/budget/dto/getEmployeeSpendingInput';
import type { PagedResultDto } from 'src/services/dto/pagedResultDto';

class BudgetStore {
    @observable employeeSpending: PagedResultDto<EmployeeBudgetOutput>;
    @observable employeeBudgets: PagedResultDto<EmployeeBudgetOutput>;
    @observable adjustableBudget?: AdjustableBudgetDto;

    constructor() {
        makeObservable(this);
    }

    @action
    async getSpending(getRequest: GetEmployeeSpendingInput) {
        const result = await BudgetService.getSpending(getRequest);
        runInAction(() => {
            this.employeeSpending = result;
        });
    }

    @action
    async getBudget(getRequest: GetEmployeeBudgetInput) {
        const result = await BudgetService.getBudget(getRequest);
        runInAction(() => {
            this.employeeBudgets = result;
        });
    }

    @action
    async getAdjustableBudgetForEmployer(params: { employerId: string }) {
        const result = await BudgetService.getAdjustableBudgetForEmployer(params);
        runInAction(() => {
            this.adjustableBudget = result;
        });
    }

    @action
    async updateAdjustableBudget(payload: AdjustableBudgetDto) {
        const result = await BudgetService.updateAdjustableBudget(payload);
        runInAction(() => {
            this.adjustableBudget = result;
        });
    }
}

export default BudgetStore;
